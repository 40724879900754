import React from 'react';
import cx from 'classnames';

import styles from './ExpandableComponent.module.scss';
import useExpandableComponentPresenter from './ExpandableComponent.presenter';
import { ExpandableComponentProps } from './ExpandableComponent.types';

const ExpandableComponent: React.FC<ExpandableComponentProps> = (props) => {
  const { className, contentClassName } = props;
  const { handleToggle, isOpen, content, Header } = useExpandableComponentPresenter(props);
  return (
    <div className={cx(styles.ExpandableComponent, className)}>
      <div aria-label='button' onClick={handleToggle} className={cx(styles.header)}>
        {Header(isOpen)}
      </div>
      <div className={cx(styles.content, styles[`${isOpen ? 'expanded' : ''}`], contentClassName)}>
          {content(isOpen)}
      </div>
    </div>
  );
};

export default ExpandableComponent;