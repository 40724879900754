import { ExpandableComponentProps } from './ExpandableComponent.types';
import { useCallback, useState } from 'react';

const useAccordionDropdownPresenter = (props: ExpandableComponentProps) => {
  const { Header, content } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  return {
    handleToggle,
    isOpen,
    Header,
    content,
  };
};

export default useAccordionDropdownPresenter;