import React, { memo, RefObject, useContext, useRef } from 'react';
import cx from 'classnames';
import { Bar, Line } from 'react-chartjs-2';

import ChartCardContext from '../../shared_components/molecules/ChartCard/ChartCard.context';
import Tooltip from '../../shared_components/organisms/Tooltip';
import ChartTooltip from './ChartTooltip';

import styles from './Chart.module.scss';
import useChartPresenter from './Chart.presenter';
import { ChartProps } from './Chart.types';

const ChartComponent: React.FC<ChartProps> = (props) => {
  const tooltip = useRef<HTMLDivElement>(null);
  const {
    options,
    plugins,
    mouseRef,
    chartRef,
    dataIndex,
    datasetIndex,
  } = useChartPresenter(props, tooltip);
  const {
    datasets,
    labels,
    height = 'auto',
    width,
    type,
    className,
    tooltipLayout = 'standardBar',
    aggregateCategory,
  } = props;

  //Chart Context
  const {
    isExpanded,
  } = useContext(ChartCardContext);

  const charts = {
    bar: Bar,
    line: Line,
  };
  //Store the correct type instance in the ComponentView for rendering
  const ComponentView = charts[type];

  let displayHeight = `${height}px`;
  if (isExpanded) {
    const labelsLength = (labels.length + 1) * 30;
    displayHeight = `${labelsLength}px`;
  }

  return (
    <div
      className={cx(styles.Chart, className, isExpanded && styles.expanded)}
    >
      <div
        ref={mouseRef as RefObject<HTMLDivElement>}
        className={styles.ChartWrapper}
        style={{ height: displayHeight, width: width, position: 'relative' }}
      >
        <ComponentView
          data={{
            labels,
            datasets,
          }}
          options={options}
          plugins={plugins}
          ref={chartRef}
        />
        <ChartTooltip
          ref={tooltip}
          labels={labels}
          datasets={datasets}
          layout={tooltipLayout}
          dataIndex={dataIndex}
          datasetIndex={datasetIndex}
          chartType={type}
          aggregateCategory={aggregateCategory}
        />
      </div>
    </div>
  );
};


const Chart: React.FC<ChartProps> = (props) => {
  return (
    <Tooltip.Provider>
      <ChartComponent {...props} />
    </Tooltip.Provider>
  );
};

export default memo(Chart);