import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../auth';
import { EmployeeSummary, EmpowermentSummary, RoleSummary, Summary, fetchAllData } from '../../lib/api/resources/reports';
import { useFilterModal } from '../../shared_components/organisms/FilterModal/FilterModal.context';
import { useTranslation } from 'react-i18next';
import { translateDatasets } from './pageContext.utils';


export type ChartData = {
  empowermentSummary: EmpowermentSummary[];
  roleSummary: RoleSummary[];
  rawSummary: Summary[];
  rawEmployeeSummary: EmployeeSummary[];
};
export type PageContextValue = {
  chartsData: ChartData;
  isLoading: boolean;
};

const MAX_SUPPLIER_IN_PAYLOAD = 100;

export const initialPageContext: PageContextValue = {

  chartsData: {
    empowermentSummary: [],
    roleSummary: [],
    rawSummary: [],
    rawEmployeeSummary: [],
  },
  isLoading: false,
};
export const PageContext = createContext<PageContextValue>(initialPageContext);
export const PageProvider: React.FC<{}> = ({ children }) => {
  const [chartsData, setChartsData] = useState<ChartData>(initialPageContext.chartsData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isAdmin, account } = useContext(AuthContext);
  const { filterState } = useFilterModal();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchAndUpdateChartData = async () => {
      if (filterState.selectedSuppliers.length > MAX_SUPPLIER_IN_PAYLOAD) {
        console.error('more than 100 suppliers selected');
        return;
      }
      //Only fetch chart data if filters were set
      if (!account || !filterState.selectedSuppliers?.length) {
        return;
      }

      try {
        // update loading state to true
        setIsLoading(true);
        const pageData = await fetchAllData(filterState, isAdmin);
        const translatedPageData = translateDatasets(pageData, t);

        //SET PAGE DATA
        setChartsData(translatedPageData);
      } finally {
        // update loading state to false
        setIsLoading(false);
      }
    };

    // Handle the promise returned by fetchAndUpdateChartData
    fetchAndUpdateChartData().catch(error => {
      console.error('Error fetching page data:', error);
    });
  }, [filterState, filterState.selectedSuppliers, filterState.year, account, isAdmin, t]);

  return (
    <PageContext.Provider value={{
      chartsData,
      isLoading,
    }}>
      {children}
    </PageContext.Provider>
  );
};