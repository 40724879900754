import React, { memo } from 'react';
import cx from 'classnames';

import Icon from '../../atoms/Icon';

import styles from './EITable.module.scss';
import useEITablePresenter from './EITable.presenter';
import { EITableCellProps, EITableHeaderCellProps, EITableProps, TableHeaderIconProps } from './EITable.types';
import Text from '../../atoms/Text';

const TableHeaderIcon: React.FC<TableHeaderIconProps> = ({ sortDirection }) => {
  const iconComponent = (() => {
    switch (sortDirection) {
      case 'asc':
        return <Icon asset='ArrowDown' colour={'Primary'} state={'Default'} />;
      case 'desc':
        return <Icon asset='ArrowUp' colour={'Primary'} state={'Default'} />;
      default:
        return <Icon asset='Sort' colour={'NeutralSubdued'} state={'Default'} />;
    }
  })();

  return <div className={cx(styles.tableHeaderSort)}>{iconComponent}</div>;
};

export const HeaderCell: React.FC<EITableHeaderCellProps> = (props) => {
  const {
    id,
    columnTitleView: columnView,
    isSortable,
    sortDirection,
    className,
    sortColumn,
    cellTextWeight,
    printMode,
  } = props;
  return (
    <th key={id} className={cx(styles.header)}>
      <div
        className={cx({
          className,
          [styles.cursorPointer]: isSortable,
        })}
        onClick={sortColumn}
      >
        <div className={cx(styles.tableHeaderItem)}>
          <Text
            type='p'
            size='md'
            align='left'
            color='grey-2'
            weight={cellTextWeight}
            printMode={printMode}
          >
            {columnView}
          </Text>
        </div>
        <TableHeaderIcon sortDirection={sortDirection} />
      </div>
    </th>
  );
};

export const Cell: React.FC<EITableCellProps> = (props) => {
  const {
    id,
    backgroundCellColor,
    width,
    value,
    cellTextAlignment,
    cellTextWeight,
    printMode,
  } = props;
  return (
    <td
      key={id}
      style={{
        width: width,
        backgroundColor: backgroundCellColor,
      }}
    >
      <Text
        type='p'
        size='sm'
        color='grey-2'
        align={cellTextAlignment}
        weight={cellTextWeight}
        printMode={printMode}
      >
        {value}
      </Text>
    </td>
  );
};

const EITable: React.FC<EITableProps> = (props) => {
  const {
    headers,
    rows,
  } = useEITablePresenter(props);
  const { className, printMode } = props;

  return (
    <div className={cx(styles.tableContainer, className)}>
      <table>
        <thead>
          <tr>
            {headers.map((header) => (
              <HeaderCell
                key={header.id}
                {...header}
                printMode={printMode}
              />
            ))}
          </tr>
        </thead>
        <tbody>
        {rows.map((row) => (
          <tr key={row.id}>
            {row.cells.map((cell) => (
              <Cell
                key={cell.id}
                {...cell}
                printMode={printMode}
              />
            ))}
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default memo(EITable);