import { useMemo } from 'react';
import { useFilterModal } from '../../../shared_components/organisms/FilterModal/FilterModal.context';
import { ChartTooltipLayout } from '../ChartTooltip/ChartTooltip.types';
import { IntensityChartProps } from './IntensityChart.types';
import { getIntensityDatasetAndLabels } from './IntensityChart.utils';

const useIntensityChartPresenter = (props: IntensityChartProps) => {
  const { pageView, filterState: { year } } = useFilterModal();

  const {
    data,
    datasetConfig,
    printMode,
  } = props;

  const {
    labels,
    chartDatasets: datasets,
  } = useMemo(
    () => getIntensityDatasetAndLabels(data, datasetConfig, pageView, year),
    [data, datasetConfig, pageView, year],
  );

  const tooltipLayout: ChartTooltipLayout = datasets.filter(dataset => !dataset.hidden).length > 1
    ? 'stackedBar'
    : 'standardBar';

  return {
    labels,
    datasets,
    tooltipLayout,
    showAverageLine: !printMode,
  };
};

export default useIntensityChartPresenter;