import React from 'react';
import PrintPage from '../../shared_components/atoms/Page/PrintPage';
import Text from '../../shared_components/atoms/Text';
import styles from './styles.module.scss';
import EndText from './end-text.png';
import EndLogo from './title-logo.png';

export const EndPage = () => {
  return (
    <PrintPage className={styles.endPage}>
      <div className={styles.bottomContainer}>
        <img src={EndText} style={{ width: '60%' }} alt='' />
        <div className={styles.allDetailsContainer}>
          <div className={styles.addressContainer}>
            <Text
              type='h1'
              size='lg'
              align='left'
              weight='bold'
              color='grey-2'
              printMode
            >420 N Wabash Ave</Text>
            <Text
              type='h2'
              size='md'
              align='left'
              weight='bold'
              color='grey-2'
              printMode
            >Suite 500</Text>
            <Text
              type='h2'
              size='md'
              align='left'
              weight='bold'
              color='grey-2'
              printMode
            >Chicago, IL 60611</Text>
          </div>
          <div className={styles.contactContainer}>
            <Text
              type='h1'
              size='md'
              align='left'
              weight='bold'
              color='grey-2'
              printMode
            >www.lunum.io</Text>
            <Text
              type='h1'
              size='md'
              align='left'
              weight='bold'
              color='grey-2'
              printMode
            >Phone: 312-415-5977</Text>
            <Text
              type='h1'
              size='md'
              align='left'
              weight='bold'
              color='grey-2'
              printMode
            >Email: jill@lunum.io</Text>
          </div>
        </div>
        <img src={EndLogo} style={{ width: 'fit-content' }} alt='' />
      </div>
    </PrintPage>
  );
};
