import { Industry } from '../../../lib/api/resources/industries';
import { Region } from '../../../lib/api/resources/regions';
import { Sectors } from '../../../lib/api/resources/sectors';
import { Supplier } from '../../../lib/api/resources/suppliers';
import { DataEntry } from '../../../lib/api/types';
import { CheckboxItemType } from '../../molecules/CheckboxItem/CheckboxItem.types';
import { FilterItem, SelectedFilterState, View } from './FilterModal.types';

/**
 * Sample description of the sample function that simply returns a concat of inputs
 * @param {number} sampleNumberParam This is a sample param
 * @param {string} sampleStringrParam This is a second sample param
 * @returns {string} This is a sample return value
 */
export const getLocations = (
  sampleNumberParam: number,
  sampleStringrParam: string,
): string => {
  // Returns a empty string
  return `${sampleStringrParam}:${sampleNumberParam}`;

};

const byNameComparison = (
  a: { name: string; },
  b: { name: string; },
) => a.name.localeCompare(b.name);

export const buildRegionCheckboxDropdownItems = (
  itemList: DataEntry<Region>[],
) => itemList.map(({ content, uuid }) => {
  return ({
    name: content.name,
    id: uuid,
  });
}).sort(byNameComparison);

export const buildSectorsCheckboxDropdownItems = (
  itemList: DataEntry<Sectors>[],
) => itemList.map(({ content, uuid }) => {
  return ({
    name: content.name,
    id: uuid,
    childItems: content.industries.map(industry => { return { name: industry.content.name, value: industry.content.name }; }),
  });
}).sort(byNameComparison);


const buildSuppliersCheckboxDropdownItem = ({ content, uuid }) => {
  const childItems = content.locations2
    .map(location => ({ name: `${location} | ${content.name}`, value: location }))
    .sort(byNameComparison);

  return ({
    name: content.name,
    id: uuid,
    childItems: childItems,
  });
};

export const buildSuppliersCheckboxDropdownItems = (
  itemList: DataEntry<Supplier>[],
) => itemList.map(buildSuppliersCheckboxDropdownItem);

/**
 * Checks if all available suppliers are selected in the list of selected suppliers.
 * @param selectedSuppliers An array of selected suppliers.
 * @param suppliers An array of all available suppliers.
 * @returns A boolean indicating whether all available suppliers are selected.
 */
export const isAllSuppliersSelected = (selectedSuppliers: FilterItem[], suppliers: DataEntry<Supplier>[]): boolean => {
  if (selectedSuppliers.length && suppliers.length) {
    const supplierNames = suppliers.map(supplier => supplier.content.name);
    const selectedSupplierNames = selectedSuppliers.map(entry => entry.name);
    // Check if all available supplier names are included in the selected supplier names
    return supplierNames.every(name => selectedSupplierNames.includes(name));
  }
  // Return false if either selectedSuppliers or suppliers array is empty
  return false;
};

/**
 * Formats the display string for a checkbox item, optionally including the count of its child items.
 * @param checkboxItem The checkbox item to format.
 * @returns The formatted display string.
 */
export const formatCheckboxDisplayString = (checkboxItem: CheckboxItemType): string => {
  if (checkboxItem?.childItems?.length) {
    // Return the formatted display string with the count of child items
    return `${checkboxItem.name} (${checkboxItem?.childItems.length})`;
  }
  // If no child items, return the name of the checkbox item as it is
  return checkboxItem.name;
};

/**
 * Convert selected sectors, regions, and search text into String Array.
 * @param selectedSectors An array of selected sectors for filtering.
 * @param selectedRegions An array of selected regions for filtering.
 * @param searchText Optional search text for filtering.
 * @returns An object containing string array for selected filters.
 */
export const getFilterObjFromSelectedItems = (selectedSectors: FilterItem[], selectedRegions: FilterItem[], searchText?: string) => {
  let searchTextArray: string[] = [];

  // If search text is provided and not empty, split it into an array of fragments
  if (searchText && searchText.trim().length > 0) {
    searchTextArray = searchText.trim().split(',').map(value => value.trim().toLowerCase());
  }

  return {
    // Array of search text fragments
    searchString: searchTextArray,
    // Array of sector names selected for filtering
    sectorsNames: selectedSectors?.map(selectedSector => selectedSector.name),
    // Array of industry names selected for filtering
    industriesNames: selectedSectors?.flatMap(industry => industry.childItems),
    // Array of region names selected for filtering
    regionsNames: selectedRegions?.map(region => region.name),
  };
};

/**
 * Applies a search filter on the supplied list of suppliers based on the provided search string fragments.
 * @param filteredSuppliers An array of suppliers to be filtered.
 * @param searchStringList An array of search string fragments to match against supplier names, industries, sectors, and locations.
 * @returns An array of filtered suppliers matching the search criteria.
 */
export const applySearchByNameFilter = (
  filteredSuppliers: DataEntry<Supplier>[],
  searchStringList: string[],
): DataEntry<Supplier>[] => filteredSuppliers.filter((supplier: DataEntry<Supplier>) => {

  // Extract necessary fields for string comparison.
  const supplierName: string = supplier?.content?.name?.toLowerCase();
  const industryName: string = supplier?.content?.industry?.content?.name?.toLowerCase();
  const sectorName: string = supplier?.content?.sector?.content?.name?.toLowerCase();
  const locations2: string[] = supplier?.content?.locations2;

  // Iterate over each search term to check for matches in supplier details
  for (const term of searchStringList) {
    // Check if the current term partially matches any of the supplier details
    if (
      supplierName?.includes(term?.toLowerCase()) ||
      industryName?.includes(term?.toLowerCase()) ||
      sectorName?.includes(term?.toLowerCase()) ||
      locations2?.some(location => location?.toLowerCase().includes(term.toLowerCase()))
    ) {
      return true;
    }
  }
  return false;
});


/**
 * Filters suppliers based on selected sectors, regions, and optional search text.
 * @param selectedSectors An array of selected sectors to filter by.
 * @param selectedRegions An array of selected regions to filter by.
 * @param suppliers An array of supplier data to filter.
 * @param searchText Optional search text to filter by name.
 * @returns Filtered array of suppliers based on the provided criteria.
 */
export const applySupplierFilter = (selectedSectors: FilterItem[], selectedRegions: FilterItem[], suppliers: DataEntry<Supplier>[], searchText?: string): DataEntry<Supplier>[] => {
  // If no criteria selected, return the original list of suppliers
  if (!(selectedSectors.length || selectedRegions.length || searchText?.trim().length)) {
    return suppliers;
  }

  // Extract filter parameters from selected sectors, regions, and search text
  const { searchString, sectorsNames, industriesNames, regionsNames } = getFilterObjFromSelectedItems(selectedSectors, selectedRegions, searchText);

  // Filter suppliers based on Selected Categories and Search String
  return suppliers.filter((supplier: DataEntry<Supplier>) => {
    // Filter By Sectors:
    if (sectorsNames.length && !sectorsNames.includes(supplier?.content?.sector?.content?.name)) {
      return false;
    }

    // Filter By Industries:
    if (industriesNames.length && !industriesNames.includes(supplier?.content?.industry?.content?.name)) {
      return false;
    }

    // Filter By Regions:
    if (regionsNames.length && !supplier.content.locations2.some((location) => regionsNames.includes(location))) {
      return false;
    }

    // Search on Location, Industry, Sector, Supplier Name
    if (searchString.length && !applySearchByNameFilter([supplier], searchString).length) {
      return false;
    }

    // If supplier passes all filters, include it in the filtered list
    return true;
  });
};

/**
 * Determines the appropriate page view based on the provided parameters.
 * @param isAdmin - A boolean indicating whether the user is an admin or not.
 * @param selectedSuppliers - An array of selected suppliers.
 * @returns A string representing the page view ('Empty', 'Region', 'Company', 'Portfolio').
 */
export const getPageView = (isAdmin: boolean | undefined, selectedSuppliers: FilterItem[]): View => {
  // If no suppliers are selected, return 'Empty'
  if (!selectedSuppliers.length) return 'Empty';

  if (isAdmin) {
    if (selectedSuppliers.length === 1) {
      const selectedLocations = selectedSuppliers[0].childItems;

      // If only one location is selected, return 'Region'
      if (selectedLocations && selectedLocations.length === 1) {
        return 'Region';
      }
      // If only supplier is selected, return 'Company'
      return 'Company';
    }
  }

  // If user is not admin or more than one supplier is selected, return 'Portfolio'
  return 'Portfolio';
};

export const getLocallyStoredFilters = () => {

  //Extract string of filters from Local Storage
  const filterItemString = localStorage.getItem('filterState');
  //If no string found, assume filters have not been set.
  if (!filterItemString) return undefined;

  //Extract JSON Object of Filters
  const filterItems: SelectedFilterState = JSON.parse(filterItemString);
  //If there are suppliers found filter has been set in past.
  if (filterItems && filterItems?.selectedSuppliers && filterItems?.selectedSuppliers?.length) {
    return filterItems;
  } else {
    return undefined;
  }

};

function mapEntries<T>(entries: DataEntry<T>[]) {
  const map: Record<string, DataEntry<T>> = {};
  entries.forEach((entry) => {
    map[entry.uuid] = entry;
  });
  return map;
}

export const getUuidFromDataEntry = (dataEntry: string | DataEntry<Sectors | Industry>): string => (
  typeof dataEntry === 'string' ? dataEntry : dataEntry.uuid
);

export const reconnectSectors = (
  suppliers: DataEntry<Supplier>[],
  sectors: DataEntry<Sectors>[],
) => {
  const sectorMap = mapEntries(sectors);
  return suppliers.map((supplier) => {
    const { content, ...rest } = supplier;
    // content.sector and content.industry are returned as UUIDs so they need to be remapped
    const sectorUuid = getUuidFromDataEntry(content.sector);
    const industryUuid = getUuidFromDataEntry(content.industry);

    const sector = sectorMap[sectorUuid];
    const industryMap = mapEntries(sector.content.industries);
    const industry = industryMap[industryUuid];
    return {
      ...rest,
      content: {
        ...content,
        sector,
        industry,
      },
    };
  });
};


export const mapSuppliersDataToFilterItems = (suppliersData: DataEntry<Supplier>[], isSelected: boolean): FilterItem[] => {
  if (!isSelected) {
    return suppliersData.map((supplier) => ({
      name: supplier.content.name,
      id: supplier.uuid,
      childItems: supplier.content.locations2,
    }));
  } else {
    return [];
  }
};