import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Summary } from '../../../lib/api/resources/reports';
import { DatasetConfig } from '../../../modules/Chart/Chart.types';
import { PageContext } from '../../../modules/page/PageContext';
import { useFilterModal } from '../../organisms/FilterModal/FilterModal.context';
import { KeyTakeawaysCardStateEnum } from '../../organisms/KeyTakeawaysCard/KeyTakeawaysCard';

import { FormatedEmpowermentSummary } from './WageQualityPage.types';
import { buildDataForPage, formatEmpowermentChartData } from './WageQualityPage.utils';

const useWageQualityPagePresenter = () => {
  const { t } = useTranslation();
  const { chartsData: summaryTables, isLoading } = useContext(PageContext);
  const {
    empowermentSummary,
    rawSummary,
    rawEmployeeSummary,
  } = summaryTables;

  const {
    appliedFilters,
    filterState,
    pageView,
  } = useFilterModal();

  const {
    totalWageQualityAdjustmentAmount,
    keyTakeawayValues,
  } = useMemo(() =>
    buildDataForPage(pageView, filterState.year, rawSummary, rawEmployeeSummary),
  [pageView, filterState.year, rawSummary, rawEmployeeSummary]);

  // Key Takeaways Card
  const keyTakeawaysState: KeyTakeawaysCardStateEnum = isLoading ? 'Loading' : 'Default';
  const keyTakeawaysCard = {
    state: keyTakeawaysState,
    values: keyTakeawayValues,
  };

  // Wage Quality Adjustment Intensity Chart
  const wageQualityIntensityChartDatasetConfig: DatasetConfig<Summary>[] = useMemo(() => [
    {
      datasetLabel: t('chartDatasetKeys.wageQualityAdjustment'),
      datasetProperty: 'wageQualityAdjustment',
      color: 'BLUE',
      hidden: true,
    },
    {
      datasetLabel: t('chartDatasetKeys.wageEquity'),
      datasetProperty: 'wageEquity',
      color: 'PURPLE',
    },
    {
      datasetLabel: t('chartDatasetKeys.incomeSatiation'),
      datasetProperty: 'incomeSatiation',
      color: 'GREEN',
    },
    {
      datasetLabel: t('chartDatasetKeys.livingWage'),
      datasetProperty: 'livingWage',
      color: 'BLUE',
    },
  ], [t]);
  const wageQualityIntensityChart = {
    data: rawSummary,
    datasetConfig: wageQualityIntensityChartDatasetConfig,
  };

  // Wage Quality Adjustment YoY Chart
  const wageQualityYoYChartDatasetConfig: DatasetConfig<Summary>[] = useMemo(() => [
    {
      datasetLabel: 'Wage Quality Adjustment',
      datasetProperty: 'wageQualityAdjustment',
      color: 'BLUE',
    },
    {
      datasetLabel: 'Wage Equity',
      datasetProperty: 'wageEquity',
      color: 'PURPLE',
      hidden: true,
    },
    {
      datasetLabel: 'Income Satiation',
      datasetProperty: 'incomeSatiation',
      color: 'GREEN',
      hidden: true,
    },
    {
      datasetLabel: 'Living Wage',
      datasetProperty: 'livingWage',
      color: 'BLUE',
      hidden: true,
    },
  ], []);
  const wageQualityYoYChart = {
    data: rawSummary,
    datasetConfig: wageQualityYoYChartDatasetConfig,
  };

  //Props for the proportional empowerment chart
  const empowermentDatasetConfig: DatasetConfig<FormatedEmpowermentSummary>[] = useMemo( ()=>{
    return [
      {
        datasetLabel: t('legendLabels.wageQuality.Reliant'),
        datasetProperty: 'Reliant',
        color: 'BLUE',
      },
      {
        datasetLabel: t('legendLabels.wageQuality.Mostly Reliant'),
        datasetProperty: 'Mostly Reliant',
        color: 'GREEN',
      },
      {
        datasetLabel: t('legendLabels.wageQuality.Somewhat Reliant'),
        datasetProperty: 'Somewhat Reliant',
        color: 'PURPLE',
      },
      {
        datasetLabel: t('legendLabels.wageQuality.Self Reliant'),
        datasetProperty: 'Self Reliant',
        color: 'TEAL',
      },
      {
        datasetLabel: t('legendLabels.wageQuality.Empowered'),
        datasetProperty: 'Empowered',
        color: 'RED',
      },
    ];
  }
  , [t]);

  const [
    femaleEmpowermentChart,
    maleEmpowermentChart,
  ] = useMemo(() => [
    {
      data: formatEmpowermentChartData('female', empowermentSummary),
      datasetConfig: empowermentDatasetConfig,
      yLabel: 'ethnicity',
      aggregateCategory: 'Female',
    },
    {
      data: formatEmpowermentChartData('male', empowermentSummary),
      datasetConfig: empowermentDatasetConfig,
      yLabel: 'ethnicity',
      aggregateCategory: 'Male',
    },
  ], [empowermentSummary, empowermentDatasetConfig]);

  return {
    view: pageView,
    // Applied Filters for each Chart Card
    appliedFilters,
    // Total Adjutsment Amount Card
    totalWageQualityAdjustmentAmount,
    // Data for Gender Ethnicity Chart
    genderEthnicityChartData: rawEmployeeSummary,
    // Key Takeaways Card Props
    keyTakeawaysCard,
    // Intensity Chart Props
    wageQualityIntensityChart,
    // YoY Chart Props
    wageQualityYoYChart,
    // Empowerment Chart Props
    femaleEmpowermentChart,
    maleEmpowermentChart,
  };
};

export default useWageQualityPagePresenter;