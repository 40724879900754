import { DataFrame, WhereClause } from '../modules/data_frame/DataFrame';
import { FilterItem, SelectedFilterState, View } from '../shared_components/organisms/FilterModal/FilterModal.types';
import { BaseSummary, ReportRequestPayload, Summary } from './api/resources/reports';
import { STATE_CODES, YEAR_FILTER_ITEMS } from './constants';
type ReqNameType = 'summary' | 'employee_summary' | 'empowerment_summary' | 'role_summary';

export const isEmptyString = (value?: string) => (value ? value.trim().length === 0 : true);

export const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1;

export const formatPhoneNumber = (value: string): string => {
  const formattedInput = value.replace(/\D/g, '');

  // Format the input into 000-000-0000 format
  const formattedNumberArray = formattedInput.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/) || [];
  return !formattedNumberArray[2] ? formattedNumberArray[1]
    : `${formattedNumberArray[1]}-${formattedNumberArray[2]}${formattedNumberArray[3] ? `-${formattedNumberArray[3]}` : ''}`;
};

export const formatAmount = (value: number | string | undefined, includeDollarSign = true): string => {
  if (Number.isNaN(value)) {
    return 'n/a';
  }
  const isValueNegative = Number(value) < 0;
  const amount = Math.abs(Number(value)).toLocaleString();
  const amountString = `${includeDollarSign ? '$' : ''}${amount}`;
  return isValueNegative ? `(${amountString})` : amountString;
};

/**
 * Generates array of rows for value from the dataset based on the specified column name.
 * @param {T[]} dataset - Data is array of objects.
 * @param {keyof T} columnName - The column name to group the dataset by.
 * @returns {Record<string, T[]>} An object containing groups of items based on the column values.
 */
export const getRowsForValue = <T>(dataset: T[], columnName: keyof T): Record<string, T[]> => {
  // Reducing the dataset to generate value groups
  return dataset.reduce((groups, item) => {
    // Extracting the value of the specified column
    const key = item[columnName];
    // Ensure the key is a string or number
    const groupKey = String(key);
    // Initializing an array for the group if it doesn't exist
    groups[groupKey] = groups[groupKey] || [];
    // Adding the item to the corresponding group
    groups[groupKey].push(item);
    return groups;
  }, {});
};

export const getSupplierNames = (selectedSuppliers: FilterItem[]): string[] => {
  return selectedSuppliers.map((supplier) => supplier.name);
};

export const getStateShortForm = (stateName: string): string => {
  const formattedState = stateName.trim().toLowerCase();
  return STATE_CODES[formattedState] || '';
};

export const getYearSummaryTotalAdjustmentValue = (rawSummary: Summary[], adjustment: keyof Summary, year: number) => {
  const summaryDF = new DataFrame(rawSummary).query({
    where: [{
      comparator: (props) => {
        return props.row.year === year;
      },
    }],
  });
  // rounded value for total adjustment
  const totalAdjustmentValue = Math.round(summaryDF.columnSum(adjustment));
  return {
    summaryDF: summaryDF,
    totalAdjustmentValue: totalAdjustmentValue,
  };
};

export const getUniqueLocations = (selectedSuppliers: FilterItem[]) => {
  // Extracting Locations from selectedSuppliers.
  const remainingLocations = selectedSuppliers.flatMap(supplier => supplier.childItems);
  // filtering valid values
  const validaLocations = remainingLocations.filter((location) => !!location) as string[];
  // returning unique locations
  return [...new Set(validaLocations)];
};

export const filterDataOnLocation = <SummaryType extends BaseSummary>(unfilteredData: SummaryType[], selectedSuppliers: FilterItem[]) => {

  if (!selectedSuppliers.length) {
    return unfilteredData;
  }
  //Get array of filters
  const filtersStr = selectedSuppliers.flatMap(supplier => supplier.childItems?.map(item => `${supplier.name}.${item}`));

  return unfilteredData.filter((record) => filtersStr.includes(`${record.supplier}.${record.location}`));
};

const generateEmployeeSummaryReportsPayload = (supplierNames: string[], uniqueLocations: string[], pageView: View, year: number): ReportRequestPayload => {
  if (pageView === 'Portfolio') {
    return {
      suppliers: supplierNames,
      years: [year],
      groupBy: ['gender', 'ethnicity', 'year'],
    };
  } else if (pageView === 'Company') {
    return {
      suppliers: supplierNames,
      years: [year],
      locations: uniqueLocations,
      groupBy: ['gender', 'ethnicity', 'year', 'company'],
    };
  } else {
    return {
      suppliers: supplierNames,
      years: YEAR_FILTER_ITEMS,
      locations: uniqueLocations,
    };
  }
};

const generateEmpowermentSummaryReportsPayload = (supplierNames: string[], uniqueLocations: string[], pageView: View, year: number): ReportRequestPayload => {
  if (pageView === 'Portfolio') {
    return {
      suppliers: supplierNames,
      years: [year],
      groupBy: ['empowerment', 'year', 'gender', 'ethnicity'],
    };
  } else if (pageView === 'Company') {
    return {
      suppliers: supplierNames,
      years: [year],
      locations: uniqueLocations,
      groupBy: ['empowerment', 'year', 'gender', 'ethnicity'],
    };
  } else {
    return {
      suppliers: supplierNames,
      years: YEAR_FILTER_ITEMS,
      locations: uniqueLocations,
      groupBy: ['empowerment', 'year', 'gender', 'ethnicity'],
    };
  }
};

const generateRoleSummaryReportsPayload = (supplierNames: string[], uniqueLocations: string[]): ReportRequestPayload => {
  return {
    suppliers: supplierNames,
    years: YEAR_FILTER_ITEMS,
    locations: uniqueLocations,
  };

};

const generateSummaryReportsPayload = (supplierNames: string[], uniqueLocations: string[], pageView: View): ReportRequestPayload => {
  return {
    suppliers: supplierNames,
    years: YEAR_FILTER_ITEMS,
    locations: pageView !== 'Portfolio' ? uniqueLocations : undefined,
  };
};

export const generateReportsPayload = (selectedFilterState: SelectedFilterState, pageView: View, reqName: ReqNameType): ReportRequestPayload => {
  const { selectedSuppliers, year } = selectedFilterState;
  const uniqueLocations = getUniqueLocations(selectedSuppliers);
  const supplierNames = getSupplierNames(selectedSuppliers);
  if (reqName === 'summary') {
    return generateSummaryReportsPayload(supplierNames, uniqueLocations, pageView);
  }
  if (reqName === 'employee_summary') {
    return generateEmployeeSummaryReportsPayload(supplierNames, uniqueLocations, pageView, year);
  }
  if (reqName === 'empowerment_summary') {
    return generateEmpowermentSummaryReportsPayload(supplierNames, uniqueLocations, pageView, year);
  }
  if (reqName === 'role_summary') {
    return generateRoleSummaryReportsPayload(supplierNames, uniqueLocations);
  }
  return {
    suppliers: supplierNames,
    years: YEAR_FILTER_ITEMS,
  };
};


// This function checks whether the provided input, which can be either a string or an array, is empty.
export const isEmpty = (input: string | any[]): boolean => {
  // Check if the input is a string or an array and return if it's empty

  // If the input is a string, trim it to remove leading and trailing whitespace, then check if its length is 0.
  if (typeof input === 'string') {
    return input.trim().length === 0;
  }

  // For arrays, check if the length is 0.
  return input.length === 0;
};

// This function calculates the intensity values based on the provided summary data and type of value to calculate.
export const calcIntensity = (row: Summary, type: string) => {
  // Calculate the intensity value by dividing the specified type of value by the total count of people.
  const result = row[type] / row.count;

  // Check if the result is NaN (Not a Number) or infinite. If so, return 0.
  if (isNaN(result) || !isFinite(result)) {
    return 0;
  }

  // Return the calculated intensity value.
  return result;
};

export const round = (
  value: string | number,
  roundValues: boolean,
): string | number => {
  if (!roundValues || typeof value === 'string') return value;
  return Math.round(value);
};

export const getMinMaxOnCalculatedField = (
  df: DataFrame<Record<string, string | number>>,
  key: string,
  type: 'min' | 'max',
) => {
  const orderDirection = type === 'max' ? 'desc' : 'asc';
  const sortedDataset = df.query({
    orderBy: [key],
    orderDirection: orderDirection,
  }).getDataset();
  return sortedDataset[0];
};

export const getMinMaxForKeyTakeaway = (
  filteredDF: DataFrame<Record<string, string | number>>,
  intensityKeys: string[],
  groupByKeys: string[],
  minKey: string,
  maxKey: string,
  isIntensity = true,
  where?: WhereClause<Record<string, string | number>>[],
) => {
  const selectList = isIntensity ? intensityKeys.map((key) => {
    return {
      as: key,
      calculator: (props) => {
        return calcIntensity(props.row, key);
      },
    };
  }) : intensityKeys;

  const queriedDF = filteredDF.query({
    groupBy: groupByKeys,
    select: [
      ...selectList,
      ...groupByKeys,
      'count',
    ],
    where: where,
  });

  const largestDiversityAdjustmentSupplier = getMinMaxOnCalculatedField(queriedDF, maxKey, 'max');
  const smallestDiversityAdjustmentSupplier = getMinMaxOnCalculatedField(queriedDF, minKey, 'min');

  return {
    minRecord: smallestDiversityAdjustmentSupplier,
    maxRecord: largestDiversityAdjustmentSupplier,
    queriedDF: queriedDF,
  };
};

export const getYoYDiffForProperty = (
  datasets: Record<string, string | number>[],
  property: string,
  year: number,
) => {
  const prevYearRecord = datasets.find((dataset) => dataset.year === (year - 1));
  const prevYearValue = prevYearRecord?.[property] as number;

  const curYearRecord = datasets.find((record) => record.year === year);
  const curYearValue = curYearRecord?.[property] as number || 0;
  if (!prevYearRecord || !prevYearValue) {
    return {
      currentYearRecord: curYearRecord,
      diffInPercentage: 0,
      diffText: 'increased',
    };
  }

  const percentageChange = ((curYearValue - prevYearValue) / Math.abs(prevYearValue)) * 100;
  return {
    currentYearRecord: curYearRecord,
    diffInPercentage: percentageChange ? Math.abs(percentageChange).toFixed(2) : 0,
    diffText: percentageChange < 0 ? 'decreased' : 'increased',
  };
};