import { useMemo } from 'react';
import { DataSetRecord } from '../../data_frame/DataFrame';
import percentageFormatter from '../AnnotationFormatter/precentageFormatter';
import { ProportionalChartProps } from './ProportionalChart.types';
import { buildProportionalChartDatasets, buildProportionalChartLabels } from './ProportionalChart.utils';

const useProportionalChartPresenter = <T extends DataSetRecord>( props: ProportionalChartProps<T> ) => {
  const {
    data,
    yLabel,
    datasetConfig,
  } = props;

  const labels = useMemo(
    () => buildProportionalChartLabels(data, yLabel),
    [data, yLabel],
  );
  const datasets = useMemo(
    () => buildProportionalChartDatasets(data, yLabel, datasetConfig),
    [data, yLabel, datasetConfig],
  );
  const annotationFormatter = percentageFormatter(1);

  return {
    labels,
    datasets,
    annotationFormatter,
  };
};

export default useProportionalChartPresenter;