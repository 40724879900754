import { useCallback, useMemo } from 'react';
import { formatAmount, getRowsForValue, getStateShortForm } from '../../../lib/utils';
import { extractDataset, getDatasetMax, getDatasetMin, getDatasetSum } from '../../../modules/Chart/Chart.utils';
import { MapChartProps } from './MapChart.types';
import { getIntensityColors } from './MapChart.utils';

const buildOptions = (props: MapChartProps) => {
  const { data, selectedState, printMode } = props;
  const tooltip = {
    formatter: (params) => {
      // to hide tooltip for region with no data
      if (!params.data?.value) {
        return null;
      }
      return `${params.data.name} <br/> ${formatAmount(params.data.value)}`;
    },
    backgroundColor: '#FFF',
    borderColor: 'rgb(42, 97, 137)',
    borderWidth: 2,
    textStyle: {
      color: printMode ? '#000' : '#2F3846',
    },
  };

  // group data by location.
  const locationData = getRowsForValue(data || [], 'location');
  const locationTotalsMap: Record<string, number> = {};
  // sum all jobCreationAdjustment values for each location.
  Object.keys(locationData).forEach((locationKey) => {
    locationTotalsMap[locationKey] = getDatasetSum(extractDataset(locationData[locationKey], 'jobCreationAdjustment'));
  });

  // find the min and max jobCreationAdjustment sum from all locations.
  const max = getDatasetMax(Object.values(locationTotalsMap));
  let min = getDatasetMin(Object.values(locationTotalsMap));
  min = min === max ? 0 : min;

  // to show different colors based on values
  const visualMap = {
    type: 'piecewise',
    // To hide the visual map component in the map
    // start
    left: -500,
    top: -500,
    // end
    min: min,
    max: max,
    outOfRange: {
      color: '#F4F5F8',
    },
    inRange: {
      color: [
        '#D0E8F5',
        '#A1D0EB',
        '#5BADDB',
        '#148BCC',
        '#004282',
      ],
    },
    calculable: true,
  };

  // config for selected area

  // emphasis config for hovered area
  const emphasis = {
    label: {
      color: null,
    },
    itemStyle: {
      areaColor: null,
      borderColor: '#002664',
      borderWidth: 2,
    },
  };

  const intenistyIncrement = (max - min) / 5;

  // loop over all the jobCreationAdjustment sums and create map data
  const dataWithConfig = Object.keys(locationTotalsMap).map((locationKey) => {
    const shortStateName = getStateShortForm(locationKey);
    const { fontColor, bgColor } = getIntensityColors(locationTotalsMap[locationKey], intenistyIncrement, printMode || false);
    return {
      name: locationKey,
      value: locationTotalsMap[locationKey],
      label: {
        show: true,
        color: fontColor,
        fontSize: '0.57831rem',
        formatter: () => shortStateName,
      },
      emphasis: {
        label: {
          color: fontColor,
        },
        itemStyle: {
          areaColor: bgColor,
        },
      },
      selected: !!selectedState && locationKey === selectedState,
      // selection config for each
      select: {
        label: {
          color: fontColor,
        },
        itemStyle: {
          areaColor: bgColor,
        },
      },
    };
  });

  // to show reset zoom button in map chart
  const toolbox = {
    show: !printMode,
    left: 'right',
    top: 'top',
    feature: {
      restore: {
        show: true,
        title: 'Reset Zoom',
        icon: 'path://M11.8295 21.225C9.81286 20.975 8.14203 20.0959 6.81703 18.5875C5.49203 17.0792 4.82953 15.3084 4.82953 13.275C4.82953 12.175 5.0462 11.1209 5.47953 10.1125C5.91286 9.10419 6.52953 8.22502 7.32953 7.47502L8.75453 8.90002C8.1212 9.46669 7.64203 10.125 7.31703 10.875C6.99203 11.625 6.82953 12.425 6.82953 13.275C6.82953 14.7417 7.2962 16.0375 8.22953 17.1625C9.16286 18.2875 10.3629 18.975 11.8295 19.225V21.225ZM13.8295 21.225V19.225C15.2795 18.9584 16.4754 18.2667 17.417 17.15C18.3587 16.0334 18.8295 14.7417 18.8295 13.275C18.8295 11.6084 18.2462 10.1917 17.0795 9.02502C15.9129 7.85836 14.4962 7.27502 12.8295 7.27502H12.7545L13.8545 8.37502L12.4545 9.77502L8.95453 6.27502L12.4545 2.77502L13.8545 4.17502L12.7545 5.27502H12.8295C15.0629 5.27502 16.9545 6.05002 18.5045 7.60002C20.0545 9.15002 20.8295 11.0417 20.8295 13.275C20.8295 15.2917 20.167 17.0542 18.842 18.5625C17.517 20.0709 15.8462 20.9584 13.8295 21.225Z',
      },
    },
  };

  // selected state style
  const serieseSelectConfig = {
    itemStyle: {
      borderColor: '#002664',
      borderWidth: 2,
    },
  };

  const zoomLevel = 1.2;

  const options = {
    tooltip,
    visualMap,
    roam: !printMode,
    toolbox,
    scaleLimit: {
      min: zoomLevel, // to limit zooming out
    },
    series: [
      {
        type: 'map',
        map: 'USA',
        emphasis,
        zoom: zoomLevel,
        label: {
          show: false,
        },
        selectedMode: 'single',
        data: dataWithConfig,
        select: serieseSelectConfig,
      },
    ],
  };

  return options;
};

const useMapChartPresenter = (props: MapChartProps) => {
  const { updateState } = props;
  const handleRegionClick = useCallback((params) => {
    if (params.data) {
      updateState?.(params.data?.name);
    }
  }, [updateState]);
  const buildOptionsCallback = useMemo(() => buildOptions(props), [props]);
  return {
    options: buildOptionsCallback,
    handleRegionClick,
  };
};

export default useMapChartPresenter;