const memoized = {};
const percentageFormatter = ( limit: number ) => {
  memoized[limit] ??= (value, ctx) => {

    //if the value is less than 1 don't show on chart
    if (value < limit) return '';

    //strip away decimal places and format with percentage
    return `${Math.floor(value)}%`;
  };
  return memoized[limit];
};

export default percentageFormatter;